import React, { Component } from "react";

import {
  Table,
  Space,
  Button,
  Input,
  Form,
  Modal,
  Tooltip,
  message,
  Switch,
} from "antd";
import {
  AudioOutlined,
  InfoCircleOutlined,
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";

import UserService from "../../services/UserService";
import DepositModal from "../FormEditUser/dePosit";
import DeleteUser from "../FormEditUser/deleteUser";
import EditUser from "../FormEditUser";

const { Search } = Input;

let resData;
const admin = localStorage.getItem("user");
const dataAdmin = JSON.parse(admin);
class ListUser extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  state = {
    visible1: false,
    visibleDelete: false,
    visible2: false,
    visibleAdd: false,
    type: "User",
    modalData: "",
    modalView: "",
    usersData: [],
    users: [],
  };

  showModal2 = (text) => {
    this.setState({
      visible2: true,
      modalData: text,
    });
  };
  onCloseEdit = (status) => {
    // console.log(status);
    if (status) {
      this.setState({
        visible2: false,
        modalData: "",
      });
    }
  };

  showModalDeposit = (text) => {
    this.setState({
      visible1: true,
      modalView: text,
    });
  };

  onCloseDeposit = (status) => {
    if (status) {
      this.setState({
        visible1: false,
        modalView: "",
      });
    }
  };

  handleCancel1 = (e) => {
    this.setState({
      visibleDelete: false,
    });
  };

  reLoad = async (status) => {
    if (status) {
      await UserService.getUsers(dataAdmin.admin_id)
        .then(async (response) => {
          resData = response.data;
          this.setState({ users: resData, usersData: resData });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  showModalDelete = (text) => {
    this.setState({
      visibleDelete: true,
      modalView: text,
    });
  };

  componentDidMount() {
    let exp = localStorage.getItem("token");
    if (exp != null) {
      UserService.expires(exp);
    }
    UserService.getUsers(dataAdmin.admin_id)
      .then(async (response) => {
        resData = response.data;
        this.setState({ users: resData, usersData: resData });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  showModal = () => {
    this.setState({
      visibleAdd: true,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visibleAdd: false,
    });
  };
  onChange = (text, row) => {
    const newData = {
      userId: row.user_id,
      status: text ? "Inactive" : "Active",
    };

    UserService.updateStatus(newData)
      .then((e) => {
        // console.log(e);
        this.reLoad(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleAdd = async (e) => {
    const newData = {
      userId: e.username,
      password: e.password,
      lineId: e.lineId,
      credit: e.credit || 0,
      phoneNumber: e.Phone,
      adminId: dataAdmin.admin_id,
      userType: "User",
    };

    await UserService.signup(newData)
      .then((response) => {
        // console.log(response.data);
        if (response.data.message === "Not enough credit") {
          message.error({
            content: "credit ไม่พอ",
            className: "custom-class",
            style: {
              marginTop: "20vh",
              fontSize: "16px",
            },
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    this.reLoad(true);
    this.handleCancel();
  };

  columns = [
    {
      title: "ชื่อผู้ใช้",
      dataIndex: "user_id",
      key: "user_id",
    },
    {
      title: "เบอร์โทร",
      dataIndex: "phone_number",
      key: "phone_number",
      responsive: ["lg"],
    },
    {
      title: "ไลน์ ไอดี",
      dataIndex: "line_id",
      key: "line_id",
      responsive: ["lg"],
    },
    { title: "รหัสผ่าน", dataIndex: "password", key: "password" },
    {
      title: "เครติด",
      dataIndex: "credit",
      key: "credit",
      responsive: ["lg"],
    },
    {
      title: "Active",
      dataIndex: "",
      key: "",
      responsive: ["lg"],
      render: (text, row) => {
        // console.log(row.status);
        return (
          <Space size="middle">
            {/* eslint-disable-next-line */}
            <Switch
              onChange={() =>
                this.onChange(row.status === "Active" ? true : false, row)
              }
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked={row.status === "Active" ? true : false}
            />
          </Space>
        );
      },
    },

    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (text, row) => {
        return (
          <Space size="middle">
            {/* eslint-disable-next-line */}
            {/* <a onClick={() => this.showModalDeposit(text)}>เพิ่มเครดิต</a> */}
            {/* eslint-disable-next-line */}
            {dataAdmin.userType === "master" ? (<></>):
            (<a onClick={() => this.showModal2(text)}>แก้ไข</a>)}
            {/* eslint-disable-next-line */}
            <a onClick={() => this.showModalDelete(text)}>ลบ</a>
          </Space>
        );
      },
    },
  ];

  suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: '#1677ff',
      }}
    />
  );
  
  onSearch = (value) => {
    if(value == ""){
      this.setState({
        users: this.state.usersData
      })
    } else {
      let data = this.state.usersData
      const filterData = data.filter((user) => user.user_id === value || user.phone_number === value || user.line_id === value)
      this.setState({
        users: filterData
      })
    }
    
  };
  render() {
    return (
      <>
      <Space direction="horizontal" style={{marginBottom: 16,}}>
      <Search placeholder="ไอดี / เบอร์โทร / ไลน์ " onSearch={this.onSearch} enterButton />
      {dataAdmin.userType === "master" ? (<></>):
        (<Button
          onClick={this.showModal}
          type="primary"
        >
          เพิ่มสมาชิก
        </Button>)}
        </Space>
        <Modal
          destroyOnClose={true}
          title="เพิ่มผู้ใช้"
          visible={this.state.visibleAdd}
          onCancel={this.handleCancel}
          footer={[]}
        >
          <Form onFinish={this.handleAdd}>
            <Form.Item
              label="ชื่อผู้ใช้"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                style={{ marginLeft: "6%", width: "96%" }}
                placeholder="Enter your username"
                prefix={<UserOutlined className="site-form-item-icon" />}
                suffix={
                  <Tooltip title="Extra information">
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Form.Item>

            <Form.Item
              label="รหัสผ่าน"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                style={{ width: "97%", marginLeft: "5%" }}
                // placeholder="input password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                // style={{ marginTop: "5%" }}
              />
            </Form.Item>

            <Form.Item
              label="เบอร์โทร"
              name="Phone"
              rules={[{ required: true, message: "Please input your Phone!" }]}
            >
              <Input
                style={{ marginLeft: "5%", width: "97%" }}
                placeholder="input Phone"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                // style={{ marginTop: "5%" }}
              />
            </Form.Item>
            <Form.Item
              label="ไลน์ ไอดี"
              name="lineId"
              rules={[{ required: true, message: "Please input your lineId!" }]}
            >
              <Input
                style={{ marginLeft: "5%", width: "97%" }}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                // style={{ marginTop: "5%" }}
              />
            </Form.Item>
            <Form.Item label="เครดิต" name="credit">
              <Input
                style={{ marginLeft: "8%", width: "94%" }}
                placeholder="input Credit"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                // style={{ marginTop: "5%" }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                style={{ float: "right" }}
                // onClick={this.handleOk}
              >
                เพิ่ม
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Table columns={this.columns} dataSource={this.state.users} />
        <DepositModal
          visible={this.state.visible1}
          data={this.state.modalView}
          onClose={this.onCloseDeposit}
          onReload={this.reLoad}
          type="User"
        />
        <EditUser
          visible={this.state.visible2}
          data={this.state.modalData}
          onClose={this.onCloseEdit}
          onReload={this.reLoad}
        />

        <DeleteUser
          visible={this.state.visibleDelete}
          data={this.state.modalView}
          userType={this.state.type}
          onClose={this.handleCancel1}
          onReload={this.reLoad}
        />
      </>
    );
  }
}
export default ListUser;
