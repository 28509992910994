import React, { Component } from "react";
import UserService from "../../services/UserService";
import DeleteAdmin from "../FormEditUser/deleteAdmin";
import {
  Space,
  Table,
  Input,
  Button,
  Form,
  Modal,
  Tooltip,
  message,
} from "antd";
import {
  AudioOutlined,
  InfoCircleOutlined,
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";

import DepositModal from "../FormEditUser/dePosit";
import { render } from '@testing-library/react';

const { Search } = Input;

const admin = localStorage.getItem("user");
const dataAdmin = JSON.parse(admin);
class Admin extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  state = {
    visible: false,
    visible1: false,
    visibleDelete: false,
    listAdmin: [],
    dataListAdmin: [],
    modalView: "",
    modalData: "",
  };

  showModalDeposit = (text) => {
    this.setState({
      visible1: true,
      modalData: text,
    });
  };

  onCloseDeposit = (status) => {
    if (status) {
      this.setState({
        visible1: false,
        modalData: "",
      });
    }
  };

  async componentDidMount() {
    let exp = localStorage.getItem("token");
    if (exp != null) {
      UserService.expires(exp);
    }
    await UserService.getAdmin()
      .then((response) => {
        this.setState({ listAdmin: response.data, dataListAdmin: response.data });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  reLoad = async (status) => {
    if (status) {
      await UserService.getAdmin()
        .then((response) => {
          this.setState({ listAdmin: response.data, dataListAdmin: response.data });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showModalDelete = (text) => {
    this.setState({
      visibleDelete: true,
      modalView: text,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleOk1 = (e) => {
    this.setState({
      visible1: false,
    });
  };

  handleCancel1 = (e) => {
    this.setState({
      visibleDelete: false,
    });
  };

  handleDelete1 = (e) => {
    console.log("Delete");
  };

  handleAdd = async (e) => {
    const newData = {
      adminId: e.adminId,
      credit: e.credit || 0,
      password: e.password,
      userType: "Admin",
      admin_type: e.admin_type || "admin",
    };

    await UserService.signup(newData)
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "Not enough credit") {
          message.error({
            content: "credit ไม่พอ",
            className: "custom-class",
            style: {
              marginTop: "20vh",
              fontSize: "16px",
            },
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    this.reLoad(true);
    this.handleCancel();
  };

  columns = dataAdmin.userType === "master" ? [
    {
      title: "รหัสแอดมิน",
      dataIndex: "admin_id",
      width: "30%",
    },
    {
      title: "รหัสผ่าน",
      dataIndex: "password",
      responsive: ["sm"],
    },
    {
      title: "เครดิต",
      dataIndex: "credit",
      responsive: ["sm"],
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => this.showModalDeposit(text)}>เพิ่มเครดิต</a>
          <a onClick={() => this.showModalDelete(text)}>ลบ</a>
        </Space>
      ),
    },
  ] : [
    {
      title: "รหัสแอดมิน",
      dataIndex: "admin_id",
      width: "30%",
    },
    {
      title: "เครดิต",
      dataIndex: "credit",
      responsive: ["sm"],
    },
  ];

  suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: '#1677ff',
      }}
    />
  );
  onSearch = (value) => {
    if (value == "") {
      this.setState({
        listAdmin: this.state.dataListAdmin
      })
    } else {
      let data = this.state.dataListAdmin
      const filterData = data.filter((user) => user.admin_id === value)
      this.setState({
        listAdmin: filterData
      })
    }

  };

  render() {
    return (
      <div>
        <Space direction="horizontal" style={{ marginBottom: 16, }}>
          <Search placeholder="รหัสแอดมิน" onSearch={this.onSearch} enterButton />
          {dataAdmin.userType === "master" ? (
            <Button
              onClick={this.showModal}
              type="primary"
            >
              เพิ่มผู้ดูแลระบบ
            </Button>
          ) : (
            <></>
          )}
        </Space>

        <Table
          rowClassName={() => "editable-row"}
          bordered
          columns={this.columns}
          dataSource={this.state.listAdmin}
        />

        <Modal
          destroyOnClose={true}
          title="เพิ่มแอดมิน"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={[]}
        >
          <Form onFinish={this.handleAdd}>
            <Form.Item
              label="รหัสแอดมิน"
              name="adminId"
              rules={[
                { required: true, message: "Please input your adminId!" },
              ]}
            >
              <Input
                style={{ marginLeft: "8%" }}
                // placeholder="Enter your username"
                prefix={<UserOutlined className="site-form-item-icon" />}
                suffix={
                  <Tooltip title="Extra information">
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Form.Item>

            <Form.Item
              label="รหัสผ่าน"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                style={{ width: "95%", marginLeft: "13%" }}
                // placeholder="input password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              // style={{ marginTop: "5%" }}
              />
            </Form.Item>

            <Form.Item label="เคดิต" name="credit">
              <Input
                style={{ marginLeft: "19%", width: "88%" }}
                // placeholder="input Credit"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              // style={{ marginTop: "5%" }}
              />
            </Form.Item>
            <Form.Item label="ประเภทแอดมิน" name="admin_type">
              <Input
                style={{ marginLeft: "5%", width: "103%" }}
                // placeholder="input admin_type"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              // style={{ marginTop: "5%" }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                style={{ float: "right" }}
              // onClick={this.handleOk}
              >
                เพิ่ม
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <DepositModal
          visible={this.state.visible1}
          data={this.state.modalData}
          onClose={this.onCloseDeposit}
          onReload={this.reLoad}
          type="Admin"
        />

        <DeleteAdmin
          visible={this.state.visibleDelete}
          data={this.state.modalView}
          onClose={this.handleCancel1}
          onReload={this.reLoad}
        />

        <style jsx>
          {`
            .ant-modal-body {
              padding: 10px 10%;
            }
          `}
        </style>
      </div>
    );
  }
}

export default Admin;
