import React, { Component } from "react";

// eslint-disable-next-line
import { Row, Col, Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "axios";
import UserService from "../../services/UserService";

const user = localStorage.getItem("user");

class Login extends Component {
  componentDidMount() {
    let exp = localStorage.getItem("token");
    if (exp != null) {
      UserService.expires(exp);
    }
  }

  onFinish = async (values) => {
    const data = {
      username: values.userId,
      password: values.password,
      userType: "Admin",
    };
    await axios
      .post(`${process.env.REACT_APP_URL_LOGIN}`, data)
      .then((res) => {
        console.log(res);
        const token = res.data.token;
        const dataUser = {
          username: values.userId,
          password: values.password,
          admin_id: res.data.admin_id,
          userType: res.data.userType,
        };
        UserService.setUserSession(dataUser, token);

        window.location.href = "/home";
      })
      .catch((e) => {
        if (e) {
          message.error({
            content: "Check User and Password",
            className: "custom-class",
            style: {
              marginTop: "20vh",
              fontSize: "16px",
            },
          });
        }
      });
  };

  render() {
    console.log(user);
    return (
      <>
        {user ? (
          window.location.assign("/home")
        ) : (
          <Row justify="space-around" align="middle">
            <Col span={24}>
              <Form
                name="normal_login"
                className="login-form form-custom"
                initialValues={{ remember: true }}
                onFinish={this.onFinish}
              >
                <Form.Item
                  label="username"
                  name="userId"
                  rules={[
                    { required: true, message: "Please input your Username!" },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item
                  label="password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>

                {/* <Form.Item
                  label="Code"
                  name="verifyCode"
                  rules={[
                    {
                      required: true,
                      message: "Please input your verifyCode!",
                    },
                  ]}
                >
                  <Input placeholder="Code" />
                </Form.Item> */}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    style={{ width: "100%" }}
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}

        <style jsx>
          {`
            body {
              background: url("/images/cards-1030852.jpg");
              background-repeat: no-repeat;
              background-size: cover;
              min-height: 100vh;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .form-custom {
              margin: 70% 0;
              padding: 10%;
              background-color: #244f57;
              border: 2px solid white;
              opacity: 0.9;
              -webkit-box-shadow: 0 10px 6px -6px #777;
              -moz-box-shadow: 0 10px 6px -6px #777;
              box-shadow: 0 10px 6px -6px #777;
              border-radius: 25px;
            }
            #components-form-demo-normal-login .login-form {
              max-width: 100%;
            }
          `}
        </style>
      </>
    );
  }
}
export default Login;
