import http from "../http-common";
import jwtDecode from "jwt-decode";

// eslint-disable-next-line
const getUsers = (adminId) => {
  return http.get(`/user/users?adminId=${adminId}`);
};

// eslint-disable-next-line
const getPreUser = () => {
  // return http.get(`/tutorials/${id}`);
  return http.get(`/user/pre-users`);
};

const signup = (data) => {
  return http.post("/auth/signup", data);
};

const createUser = (data) => {
  return http.post("/create/member", data);
};

const getAdmin = () => {
  return http.get("/admin");
};

const updateUser = (filter, conditions) => {
  return http.post("user/update-users", { filter, conditions });
};

const deleteAdmin = (adminId) => {
  return http.post("/admin/delete", adminId);
};

const dePosit = (data) => {
  return http.post("member/deposit", data);
};

const getCode = (amount, adminId) => {
  return http.get(`code?value=${amount}&adminId=${adminId}`);
};

const userTopup = (data) => {
  return http.post("/user/credit-topup", data);
};

const adminTopup = (data) => {
  return http.post("/admin/credit-topup", data);
};

const updateStatus = (data) => {
  return http.post("/user/status", data);
};

const withDraw = (data) => {
  return http.post("member/withdraw", data);
};

const deleteUser = (data) => {
  return http.post("/user/delete", data);
};

const setUserSession = (user, exp) => {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", exp);
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  window.location.href = "/";
};

const expires = (token) => {
  const exp = jwtDecode(token);
  const getTime = new Date().getTime() / 1000;
  if (getTime >= exp) {
    logout();
  }
};

const getWinLose = () => {};

// eslint-disable-next-line
export default {
  getUsers,
  getPreUser,
  getAdmin,
  signup,
  createUser,
  updateUser,
  deleteUser,
  deleteAdmin,
  getWinLose,
  dePosit,
  withDraw,
  setUserSession,
  logout,
  expires,
  userTopup,
  adminTopup,
  getCode,
  updateStatus,
};
